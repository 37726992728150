import { MarketInputSearch } from '@market/react';

import styles from 'components/v2/offers-search-bar.module.css';

const ENTER_KEY_CODE = 13;

const OffersSearchBar = ({
  value,
  onChange,
  onPressEnter,
  onClear,
}: {
  value: string;
  onChange: (arg: string) => void;
  onPressEnter?: (val: string) => void;
  onClear: () => void;
}) => {
  const handleOnChange = (event: any) => {
    onChange(event.detail.current);
  };

  const handleKeyUp = (event: any) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      onPressEnter && onPressEnter((event.target as HTMLInputElement).value);
    }
  };

  return (
    <MarketInputSearch
      value={value}
      className={styles.search_bar}
      onMarketInputSearchValueChange={handleOnChange}
      onMarketInputSearchCleared={() => onClear()}
      onKeyUp={handleKeyUp}
      data-testid="offers-search-bar"
      id="offers-search-bar"
      placeholder="Search"
    />
  );
};

export default OffersSearchBar;
