import { MarketAccessory } from '@market/react';

import styles from 'components/text-bubble.module.css';

export type TextBubbleProps = {
  text: string;
  iconSource: string;
};

const TextBubble = ({ text, iconSource }: TextBubbleProps) => {
  return (
    <div className={styles.textBubble}>
      <MarketAccessory
        slot="leading-accessory"
        size="image"
        className={styles.icon}
      >
        <img
          src={iconSource}
          alt="Cash sign icon"
          width={38}
          height={38}
          className={styles.icon}
        />
      </MarketAccessory>
      <p>{text}</p>
    </div>
  );
};

export default TextBubble;
