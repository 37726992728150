import {
  MarketButton,
  MarketButtonDropdown,
  MarketList,
  MarketRow,
  MarketRadio,
  MarketModalFull,
  MarketHeader,
} from '@market/react';
import { useMemo, useState, useRef } from 'react';

import styles from 'components/v2/offer-sort-dropdown.module.css';

import { useAccount } from 'hooks/use-account';

import { OffersSortOptions } from 'models/offer';
import { LABELS, sortIcons } from 'models/sort';

import { pageClickEvent } from '../../lib/eventstream/event-catalog';
import ES2Tracker from '../../lib/eventstream/tracker';
import { REGION_NOT_AVAILABLE_TYPE, ValidRegion } from 'lib/constants';

export type PlacesHeaderProps = {
  value: OffersSortOptions;
  onChange: (arg: OffersSortOptions) => void;
  refetchGeolocation: () => void;
  region: ValidRegion | REGION_NOT_AVAILABLE_TYPE
};

const OfferSortDropdown = ({
  value,
  onChange,
  refetchGeolocation,
  region
}: PlacesHeaderProps) => {
  const { account } = useAccount();
  const [showMobileSortOffer, setShowMobileSortOffer] = useState(false);
  const isActiveAccount = account && account.status === 'ACTIVE';
  const modal = useRef<HTMLMarketModalFullElement | null>(null);

  const labels = useMemo(() => {
    const updatedLabels: { [key: string]: string } = { ...LABELS };

    if (!isActiveAccount) {
      delete updatedLabels.ACTIVATED;
      delete updatedLabels.NOT_ACTIVATED;
    }

    return updatedLabels;
  }, [isActiveAccount]);

  const sortByOptions = Object.entries(labels).map(([value, label]) => (
    <MarketRow
      className={`market-row ${styles.sortOptions}`}
      value={value}
      key={`market-row-${value}`}
    >
      {label}
    </MarketRow>
  ));

  const mobileSortByOptions = Object.entries(labels).map(([value, label]) => (
    <MarketRow
      className={`market-row ${styles.mobileSortByOptionsRow}`}
      value={value}
      key={`market-row-${value}`}
    >
      {Object.keys(sortIcons).includes(value) && (
        <span className={styles.offerSortIcon}>
          <img
            src={`/icons/${sortIcons[value]}.svg`}
            alt={sortIcons[value].replace('-', ' ')}
            width={20}
            height={20}
          />
        </span>
      )}
      {label}
      <MarketRadio slot="control" />
    </MarketRow>
  ));

  const trackButtonDropdownOpenClick = () => {
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Marketplace: Sort Open: ${region}`,
      ),
    );
  };

  const handleMobileSortOpen = () => {
    trackButtonDropdownOpenClick();
    setShowMobileSortOffer(true);
  };

  const trackListSelectionDidChangeClick = (sortByValue: string) => {
    ES2Tracker.trackPageClick(
      pageClickEvent('button-click', `Marketing: Buyer: Marketplace: Sort: ${region}`, {
        detail: sortByValue.toLowerCase(),
      }),
    );
  };

  const handleOnChange = (event: any) => {
    const value = event.detail.newSelectionValue;
    if (value === OffersSortOptions.Distance) refetchGeolocation();
    onChange(value);
    trackListSelectionDidChangeClick(value);
  };

  return (
    <div className="local-offers__body__header__buttons">
      <MarketButtonDropdown
        className={styles.sortByTrigger}
        interaction="click"
        disabled={false}
        no-caret={true}
        popover-placement="bottom-start"
        persist-list={true}
        onMarketButtonDropdownOpened={trackButtonDropdownOpenClick}
      >
        <MarketButton
          className={`market-button ${styles.sortButton}`}
          slot="trigger"
        >
          <div className={styles.sortByContainer}>
            <div className={`${styles.sortIcon} ${styles.mobileSortBy}`}>
              <img src="/icons/sort.svg" alt="" width={18} height={18} />
            </div>
            <span className={`paragraph-30 ${styles.sortBy}`}>Sort by </span>
            <span className={`paragraph-30 ${styles.mobileSortBy}`}>Sort </span>
            <p className={`paragraph-30 ${styles.labelText}`}>
              {labels[value]}
            </p>
          </div>
        </MarketButton>
        <MarketList
          value={value.toString()}
          onMarketListSelectionsDidChange={handleOnChange}
          interactive
          slot="content"
        >
          {sortByOptions}
        </MarketList>
      </MarketButtonDropdown>
      <MarketButton
        className={`market-button ${styles.mobileSortByTrigger}`}
        slot="trigger"
        onClick={handleMobileSortOpen}
        data-testid="mobile-sort-trigger"
      >
        <div className={styles.sortByContainer}>
          <div className={`${styles.sortIcon} ${styles.mobileSortBy}`}>
            <img src="/icons/sort.svg" alt="" width={18} height={18} />
          </div>
          <span className={`paragraph-30 ${styles.mobileSortBy}`}>Sort </span>
        </div>
      </MarketButton>

      {showMobileSortOffer && (
        <MarketModalFull
          className={styles.mobileSortByOptions}
          onMarketDialogDismissed={() => setShowMobileSortOffer(false)}
          ref={modal}
        >
          <MarketHeader compact={true}>
            <MarketButton
              rank="primary"
              slot="actions"
              className={styles.applyButton}
              onClick={() => setShowMobileSortOffer(false)}
            >
              Apply
            </MarketButton>
          </MarketHeader>
          <h2>Sort offers</h2>
          <div>
            <MarketList
              data-testid="mobile-market-list"
              value={value.toString()}
              onMarketListSelectionsDidChange={handleOnChange}
              interactive
              slot="content"
            >
              {mobileSortByOptions}
            </MarketList>
          </div>
        </MarketModalFull>
      )}
    </div>
  );
};

export default OfferSortDropdown;
