import { MarketActivityIndicator } from '@market/react';
import styles from './loading-indicator.module.css';

export type LoadingIndicatorProps = {
  text?: string;
};

const LoadingIndicator = ({ text }: LoadingIndicatorProps) => {
  return (
    <div className={styles.container} data-testid="loading-indicator">
      <MarketActivityIndicator size="large" />
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default LoadingIndicator;
