import { useRouter } from 'next/router';
import { useEffect, useCallback, useState, CSSProperties } from 'react';
import { LockOffIcon, CashAppPayIcon } from 'svgs';

import { useAccount } from 'hooks/use-account';
import { useBanners } from 'hooks/use-banners';

import { Account } from 'models/account';
import { Banner } from 'models/banner';

import { REGION_NOT_AVAILABLE } from 'lib/constants';
import {
  getDismissedBannerIdsCookie,
  setDismissedBannerIdsCookie,
} from 'lib/cookie';
import { pageClickEvent, pageViewEvent } from 'lib/eventstream/event-catalog';
import ES2Tracker from 'lib/eventstream/tracker';

import styles from './bottom-banner.module.css';

const MAP_BANNER_ICON_TO_COMPONENT: Record<Banner['icon'], React.ReactNode> = {
  LockOff: <LockOffIcon />,
  CashAppPay: <CashAppPayIcon />,
};

const BottomBanner = () => {
  const { account } = useAccount();
  const { isLoading, banner } = useDisplayBanner();
  const onClick = useBannerClick(banner, account);
  const [closing, closed, close] = useBannerClose(banner, account);

  useEffect(() => {
    if (banner) {
      ES2Tracker.trackPageView(
        pageViewEvent(
          '/',
          account?.region ?? REGION_NOT_AVAILABLE,
          {
            page_view_detail: generateEventParams(banner, account),
          },
          `Marketing: Buyer: Marketplace: View banner`,
        ),
      );
    }
  }, [account, banner]);

  if (closed || isLoading || !banner) return null;
  const overrideStyles = bannerToStyles(banner);

  return (
    <div
      data-testid="bottom-banner"
      className={`${styles.bottomBanner} ${
        closing ? styles.exit : styles.enter
      }`}
      style={overrideStyles}
    >
      <div className={styles.contentContainer} onClick={onClick}>
        <div
          data-testid="bottom-banner-close"
          className={styles.closeButton}
          onClick={close}
        >
          <Close color={overrideStyles['color']} />
        </div>
        {MAP_BANNER_ICON_TO_COMPONENT[banner.icon]}
        <div className={styles.infoColumn}>
          <div className={styles.infoMain}>{banner.title}</div>
          <div className="paragraph-10">{banner.subtitle}</div>
        </div>
      </div>
    </div>
  );
};

function generateEventParams(banner: Banner, account: Account | null) {
  const [bonusId, bonusType] = banner.id.split(':');
  return JSON.stringify({
    account: account?.id,
    account_status: account?.status,
    bonus_id: bonusId,
    bonus_type: bonusType,
  });
}

export function useDisplayBanner(): {
  isLoading: boolean;
  banner: Banner | undefined;
} {
  const { isLoading, banners } = useBanners();
  const dismissedBannerIds = getDismissedBannerIdsCookie();
  const banner = banners.find(
    banner => !dismissedBannerIds.includes(banner.id),
  );

  return {
    isLoading,
    banner,
  };
}

export function useBannerClick(
  banner: Banner | undefined,
  account: Account | null,
) {
  const router = useRouter();

  return useCallback(
    (e: React.MouseEvent) => {
      if (!banner) return;

      e.preventDefault();

      ES2Tracker.trackPageClick(
        pageClickEvent(
          'button-click',
          `Marketing: Buyer: Marketplace: Click banner: ${account?.region}`,
          { detail: generateEventParams(banner, account) },
        ),
      );

      router.push(banner.url);
    },
    [account, banner, router],
  );
}

export function useBannerClose(
  banner: Banner | undefined,
  account: Account | null,
): [boolean, boolean, React.MouseEventHandler] {
  const [closing, setClosing] = useState(false);
  const [closed, setClosed] = useState(false);

  const close = useCallback(
    (e: React.MouseEvent) => {
      if (!banner) return;

      e.preventDefault();
      e.stopPropagation();

      setClosing(true);
      ES2Tracker.trackPageClick(
        pageClickEvent(
          'button-click',
          `Marketing: Buyer: Marketplace: Close banner: ${account?.region}`,
          { detail: generateEventParams(banner, account) },
        ),
      );

      setTimeout(() => {
        setDismissedBannerIdsCookie(banner);
        setClosed(true);
      }, 500);
    },
    [banner, account],
  );

  return [closing, closed, close];
}

function Close({ color }: { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="X">
        <path
          id="Vector"
          d="M6.71004 18.71L12 13.41L17.29 18.71L18.71 17.29L13.41 12L18.71 6.71004L17.29 5.29004L12 10.59L6.71004 5.29004L5.29004 6.71004L10.59 12L5.29004 17.29L6.71004 18.71Z"
          fill={color ?? 'white'}
          fillOpacity="0.95"
        />
      </g>
    </svg>
  );
}

function bannerToStyles(banner: Banner): CSSProperties {
  switch (banner.style) {
    case 'dark-green':
    default:
      return {
        backgroundColor: '#005A44',
        color: 'rgba(255, 255, 255, 0.95)',
      };
    case 'cyan':
      return {
        backgroundColor: '#C9E8F5',
        color: 'rgba(0, 0, 0, 0.90)',
      };
  }
}

export default BottomBanner;
