import { useQuery } from '@tanstack/react-query';
import { getCategories } from 'clients/cashbacker-client';
import { GET_CATEGORIES_QUERY_KEY } from 'lib/constants';
import { isStringNumeric } from 'lib/utilities';
import Category from 'models/category';

type UseCategoriesProps = {
  isLoadingCategories: boolean;
  categories: Category[];
};

const defaultCategoryOrdering: { [key: string]: string } = {
  Coffee: '0',
  Breakfast: '1',
  Drinks: '2',
  Asian: '3',
  Sandwiches: '4',
  Salad: '5',
  Pizza: '6',
  Dessert: '7',
  American: '8',
  Mexican: '9',
  // following offers are ordered based on seller's GPV
  Soup: '10',
  Italian: '11',
  Beer: '12',
  Healthy: '13',
  Greek: '14',
  Vegan: '15',
  Burgers: '16',
  Juice: '17',
  Indian: '18',
  BBQ: '19',
  African: '20',
  Brazilian: '21',
  French: '22',
};

export function useCategories(): UseCategoriesProps {
  const { isLoading, data: categories } = useQuery<Category[]>(
    [GET_CATEGORIES_QUERY_KEY],
    async () => {
      try {
        const response = await getCategories();

        return response.categories.sort((a: Category, b: Category) => {
          const orderA = defaultCategoryOrdering[a.name] || a.name;
          const orderB = defaultCategoryOrdering[b.name] || b.name;
          if (isStringNumeric(orderA) && isStringNumeric(orderB)) {
            return Number(orderA) - Number(orderB);
          } else {
            return orderA.localeCompare(orderB);
          }
        });
      } catch {
        return categories;
      }
    },
  );

  return {
    isLoadingCategories: isLoading,
    categories: categories || [],
  };
}
