import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MarketChoiceButton } from '@market/react';
import styles from 'components/v2/single-select-pills.module.css';
import { pageClickEvent } from 'lib/eventstream/event-catalog';
import ES2Tracker from 'lib/eventstream/tracker';
import { useState } from 'react';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';
import { REGION_NOT_AVAILABLE_TYPE, ValidRegion } from 'lib/constants';

type PillOptions = {
  icon?: IconDefinition;
  value: string;
};

export type SingleSelectPillsProps = {
  defaultValue: string;
  options: Array<PillOptions>;
  onChange: (arg: any) => void;
  region: ValidRegion | REGION_NOT_AVAILABLE_TYPE
};

const SingleSelectPills = ({
  defaultValue,
  onChange,
  options,
  region,
}: SingleSelectPillsProps) => {
  const [selected, setSelected] = useState<string>(defaultValue);

  const trackSelectClick = (category: string) => {
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Marketplace: Category Filter: ${region}`,
        {
          detail: category.toLowerCase(),
        },
      ),
    );
  };

  const onSelected = (event: any) => {
    const value = event?.target?.innerText;
    onChange(value);
    setSelected(value);
    trackSelectClick(value);
  };

  const onDeselected = () => {
    onChange(defaultValue);
    setSelected(defaultValue);
  };

  return (
    <div className={styles.singleSelectPills}>
      {options.map(option => (
        <MarketChoiceButton
          size="small"
          className={styles.marketChoiceButton}
          key={`market-choice-button-${option.value}`}
          onMarketChoiceButtonSelected={onSelected}
          onMarketChoiceButtonDeselected={onDeselected}
          selected={option.value === selected ? true : undefined}
        >
          {option.icon ? (
            <FontAwesomeIcon icon={option.icon} />
          ) : (
            <FontAwesomeIcon icon={faUtensils} />
          )}
          {option.value}
        </MarketChoiceButton>
      ))}
    </div>
  );
};

export default SingleSelectPills;
