import { MarketActivityIndicator } from '@market/react';
import React from 'react';

import OfferCard from 'components/v2/offer-card';

import Offer, { OffersSortOptions } from 'models/offer';

import { REGION_NOT_AVAILABLE_TYPE, ValidRegion } from 'lib/constants';

import styles from './offer-grid.module.css';

type OfferGridProps = {
  offers: Offer[];
  isLoadingOffers: boolean;
  isOfferError: boolean;
  sort: OffersSortOptions;
  isLoadingGeolocation: boolean;
  setNewActivatedOffersList: (offer: Offer) => void;
  checkIsOfferActivated: (offer: Offer) => boolean;
  region: ValidRegion | REGION_NOT_AVAILABLE_TYPE;
};

const OfferGrid = ({
  offers,
  isLoadingOffers,
  isOfferError,
  sort,
  isLoadingGeolocation,
  setNewActivatedOffersList,
  checkIsOfferActivated,
  region,
}: OfferGridProps) => {
  const noOfferMessage = isOfferError ? (
    <div className={styles.offersNotFound} data-testid="offer-grid-error">
      <h3>
        <b>Something went wrong.</b>
      </h3>
      <p>
        Try refreshing the page, adjusting your search or selected categories.
      </p>
    </div>
  ) : (
    <div className={styles.offersNotFound} data-testid="offer-grid-not-found">
      <h3>
        <b>No results found.</b>
      </h3>
      <p>Try another search or adjust your selected categories.</p>
    </div>
  );

  return (
    <section className={styles.contentOffers}>
      {isLoadingOffers ||
      (sort === OffersSortOptions.Distance && isLoadingGeolocation) ? (
        <div
          className={styles.searchLoadingIndicator}
          data-testid="loading-indicator"
        >
          <MarketActivityIndicator size="large" />
        </div>
      ) : offers.length > 0 ? (
        <ul className={styles.offers} data-testid="offer-grid">
          {offers.map((offer: Offer) => (
            <li
              key={`${offer.id}`}
              className={styles.offer}
              suppressHydrationWarning={true}
              data-testid="displayed-offer"
            >
              <OfferCard
                offer={offer}
                setNewActivatedOffersList={setNewActivatedOffersList}
                isActivated={checkIsOfferActivated(offer)}
                region={region}
              />
            </li>
          ))}
        </ul>
      ) : (
        noOfferMessage
      )}
    </section>
  );
};

export default OfferGrid;
