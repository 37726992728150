import { MarketButton } from '@market/react';
import { Form, Formik } from 'formik';
import type {
  GetServerSidePropsContext,
  GetStaticPropsResult,
  InferGetServerSidePropsType,
} from 'next';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

import LoadingIndicator from 'components/loading-indicator';
import { OffersLoadingScreen } from 'components/offers-loading-screen';
import SubHeader from 'components/sub-header';
import BottomBanner from 'components/v2/bottom-banner';
import HowItWorksModal from 'components/v2/how-it-works-modal';
import OfferGrid from 'components/v2/offer-grid';
import OfferSortDropdown from 'components/v2/offer-sort-dropdown';
import OffersSearchBar from 'components/v2/offers-search-bar';
import PlacesToggle, { Mode } from 'components/v2/places-toggle';
import RegionDialog from 'components/v2/region-dialog';
import SingleSelectPills from 'components/v2/single-select-pills';

import { useAccount } from 'hooks/use-account';
import { useCategories } from 'hooks/use-categories';
import { OffersListState, useOffers } from 'hooks/use-offers';
import { useRegion } from 'hooks/use-region';
import { useWaitForMerchantRelationships } from 'hooks/use-wait-for-merchant-relationships';

import { ALL_CATEGORY, categoryIcons } from 'models/category';
import Offer, { OffersSortOptions } from 'models/offer';

import {
  QUERY_PARAM_SELECTED_MERCHANT_SLUG,
  ValidRegion,
  REGION_NAME_MAP,
  REGION_NOT_AVAILABLE_TYPE,
  REGION_NOT_AVAILABLE,
  BUYER_ID_HEADER_NAME,
} from 'lib/constants';
import { pageClickEvent, pageViewEvent } from 'lib/eventstream/event-catalog';
import ES2Tracker from 'lib/eventstream/tracker';
import { formatRegionQueryParam } from 'lib/formatters';

import styles from './index.module.css';

export type IndexPageProps = {
  initialOffers: Offer[];
  regionFromQueryParam: ValidRegion | REGION_NOT_AVAILABLE_TYPE;
  hasSessionCookie: boolean;
};

export async function getServerSideProps(
  context: GetServerSidePropsContext,
): Promise<GetStaticPropsResult<IndexPageProps>> {
  const buyerId = context.req.headers[BUYER_ID_HEADER_NAME];
  const initialOffers: Offer[] = [];
  const selectedMerchantSlug = context.query.selectedmerchantslug;
  const selectedRegion = context.query.region;
  const regionFromQueryParam =
    formatRegionQueryParam(selectedRegion) || REGION_NOT_AVAILABLE;

  if (selectedMerchantSlug) {
    const searchParams = new URLSearchParams(
      `${QUERY_PARAM_SELECTED_MERCHANT_SLUG}=${selectedMerchantSlug}`,
    );
    regionFromQueryParam !== REGION_NOT_AVAILABLE &&
      searchParams.append('region', regionFromQueryParam.toString());

    return {
      redirect: {
        destination: `/signin?${searchParams}`,
        permanent: false,
      },
    };
  }

  return {
    props: {
      initialOffers,
      regionFromQueryParam,
      hasSessionCookie: Boolean(buyerId),
    },
  };
}

const DEFAULT_OFFERS_LIST_STATE: OffersListState = {
  textSearchValue: '',
};

const Home = ({
  initialOffers,
  regionFromQueryParam,
  hasSessionCookie,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter();
  const { account, isLoading: isLoadingAccount } = useAccount({
    hasSessionCookie,
  });
  const [sort, setSort] = useState<OffersSortOptions>(
    OffersSortOptions.Popularity,
  );

  const { categories } = useCategories();
  const categoryFilterOptions = categories.map(category => ({
    value: category.name,
    icon: categoryIcons[category.name],
  }));

  const {
    selectedRegion,
    isSelectedRegionFetched,
    updateUnauthUserRegion,
    isValidRegion,
    centerLatLng,
    refetchGeolocation,
    isLoadingGeolocation,
  } = useRegion(regionFromQueryParam);

  const [offersCategoryFilter, setOffersCategoryFilter] =
    useState<string>(ALL_CATEGORY);

  const [offersListState, setOffersListState] = useState<OffersListState>(
    DEFAULT_OFFERS_LIST_STATE,
  );

  const { offers, activateOffer, isLoadingOffers, isOfferError } = useOffers({
    initialOffers,
    sort,
    centerLatLng,
    offersCategoryFilter,
    ...offersListState,
    region: selectedRegion,
  });

  if (account?.status === 'PENDING') {
    account.emailAddress
      ? router.push('/signin')
      : router.push('/onboarding/add-email');
  }

  const [showHowItWorksModal, setShowHowItWorksModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (isSelectedRegionFetched) {
      const es2Params = {
        page_view_detail: JSON.stringify({
          ...(account && {
            account: account.id,
            account_status: account.status,
          }),
        }),
      };
      ES2Tracker.trackPageView(pageViewEvent('/', selectedRegion, es2Params));
    }
  }, [account, isSelectedRegionFetched, selectedRegion]);

  const trackSearchEvent = (query: string) => {
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Marketplace: Search: ${selectedRegion}`,
        {
          detail: JSON.stringify({
            search: query,
            ...(account && { account: account.id }),
          }),
        },
      ),
    );
  };

  const openHowItWorksModal = () => {
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'link-click',
        `Marketing: Buyer: Marketplace: Help: ${selectedRegion}`,
        {
          detail: JSON.stringify({
            ...(account && { account: account.id }),
          }),
        },
      ),
    );
    setShowHowItWorksModal(true);
  };
  const closeHowItWorksModal = () => setShowHowItWorksModal(false);

  const checkIsOfferActivated = (selectedOffer: Offer) =>
    Boolean(selectedOffer.activatedOfferId);

  const setNewActivatedOffersList = async (activatingOffer: Offer) => {
    if (checkIsOfferActivated(activatingOffer)) return;
    if (!account) {
      router.push({
        pathname: '/signin',
        query: {
          [QUERY_PARAM_SELECTED_MERCHANT_SLUG]:
            activatingOffer.locations[0].slug,
        },
      });
      return;
    }

    await activateOffer(activatingOffer.id);
  };

  const { isLoadingMerchantRelationships } = useWaitForMerchantRelationships();
  if (isLoadingMerchantRelationships) {
    return <OffersLoadingScreen />;
  } else if (isLoadingAccount) {
    return <LoadingIndicator />;
  }

  return (
    <div market-features="typography" suppressHydrationWarning={true}>
      <SubHeader
        openHowItWorksModal={openHowItWorksModal}
        region={selectedRegion}
      />

      <main className={styles.content}>
        <section className={styles.contentHeader}>
          <div className={styles.contentNav}>
            <div className={styles.placesHeader}>
              <span className="semibold-30">Local offers</span>
              {isValidRegion && (
                <span className="paragraph-30">{`${
                  REGION_NAME_MAP[selectedRegion as ValidRegion]
                }, USA`}</span>
              )}
            </div>
            <PlacesToggle mode={Mode.List} region={selectedRegion} />
          </div>
          <div className={styles.controls}>
            <Formik
              initialValues={DEFAULT_OFFERS_LIST_STATE}
              onSubmit={({ textSearchValue }) =>
                setOffersListState(offersListState => ({
                  ...offersListState,
                  textSearchValue,
                }))
              }
            >
              {({ values, setFieldValue, submitForm, resetForm }) => (
                <Form
                  className={styles.offersListStateForm}
                  data-testid="offers-list-form"
                >
                  <OffersSearchBar
                    data-testid="offers-search-bar"
                    value={values.textSearchValue}
                    onChange={value => {
                      setFieldValue('textSearchValue', value);
                      if (!value) {
                        // In order to submit on change setTimeout is need to ensure we have the latest value.
                        setTimeout(submitForm, 0);
                      }
                    }}
                    onClear={() => {
                      resetForm({ values: DEFAULT_OFFERS_LIST_STATE });
                      // same as above
                      setTimeout(submitForm, 0);
                    }}
                    onPressEnter={value => {
                      submitForm();
                      trackSearchEvent(value);
                    }}
                  />
                  <MarketButton
                    className={styles.offersListStateFormButton}
                    type="submit"
                    data-testid="offers-list-form-search-button"
                    {...(isLoadingOffers && { 'is-loading': true })}
                  >
                    Search
                  </MarketButton>
                </Form>
              )}
            </Formik>
            {/*
            TODO
            Move the sort and filters below in the form above when we move these functions to be backend
            */}
            <OfferSortDropdown
              value={sort}
              onChange={setSort}
              refetchGeolocation={refetchGeolocation}
              region={selectedRegion}
            />
          </div>
          <SingleSelectPills
            defaultValue={ALL_CATEGORY}
            options={categoryFilterOptions}
            onChange={setOffersCategoryFilter}
            region={selectedRegion}
          />
        </section>
        <OfferGrid
          offers={offers}
          isLoadingOffers={isLoadingOffers}
          isOfferError={isOfferError}
          sort={sort}
          isLoadingGeolocation={isLoadingGeolocation}
          setNewActivatedOffersList={setNewActivatedOffersList}
          checkIsOfferActivated={checkIsOfferActivated}
          region={selectedRegion}
        />
      </main>
      {showHowItWorksModal && (
        <HowItWorksModal onMarketDialogDismissed={closeHowItWorksModal} />
      )}
      {isSelectedRegionFetched && !isValidRegion && !account && (
        <RegionDialog onMarketDialogDismissed={updateUnauthUserRegion} />
      )}
      <BottomBanner />
    </div>
  );
};

export default Home;
