import { OffersSortOptions } from 'models/offer';

export const sortIcons: { [key: string]: string } = {
  POPULARITY: 'thumbs-up',
  RATING: 'star',
  DISTANCE: 'location-arrow',
};

export const LABELS = {
  [OffersSortOptions.Popularity]: 'Most Popular',
  [OffersSortOptions.Rating]: 'Highest rated',
  [OffersSortOptions.Distance]: 'Closest to me',
  [OffersSortOptions.Recent]: 'Recently added',
  [OffersSortOptions.Percentage]: 'Offer percentage',
  [OffersSortOptions.Activated]: 'Activated',
  [OffersSortOptions.NotActivated]: 'Not activated',
  [OffersSortOptions.AlphaAZ]: 'Alphabetical A-Z',
  [OffersSortOptions.AlphaZA]: 'Alphabetical Z-A',
};
