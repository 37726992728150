import { MarketButton, MarketButtonGroup } from '@market/react';
import React, { MouseEventHandler } from 'react';

import { useAccount } from 'hooks/use-account';

import { pageClickEvent } from '../lib/eventstream/event-catalog';
import ES2Tracker from 'lib/eventstream/tracker';

import styles from './sub-header.module.css';
import TextBubble from './text-bubble';
import { REGION_NOT_AVAILABLE_TYPE, ValidRegion } from 'lib/constants';

export type SubHeaderProps = {
  openHowItWorksModal: MouseEventHandler<HTMLMarketButtonElement>;
  region: ValidRegion | REGION_NOT_AVAILABLE_TYPE
};

const SubHeader = ({ openHowItWorksModal, region }: SubHeaderProps) => {
  const { account, isLoading: isAccountLoading } = useAccount();
  const canShowButtons = !isAccountLoading && !account;
  const trackSignInButtonClick = () => {
    const es2Params = {
      detail: 'Market Place',
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Cash Back: Sign In: ${region}`,
        es2Params,
      ),
    );
  };

  return (
    <div className={styles.subHeaderContainer}>
      <div
        className={`${styles.subHeader} ${!account && styles.unauthenticated}`}
      >
        <div className={styles.container}>
          {account ? (
            <>
              <h1>Activate offers to start earning cash back</h1>
              <p className={`${styles.subtitle} paragraph-30`}>
                Activate offers from local businesses below and make a purchase
                to start earning cash back.
              </p>
            </>
          ) : (
            <>
              <h1>Shop local. Support your community. Get rewarded.</h1>
              <p className={`${styles.subtitle} paragraph-30`}>
                Join Square&apos;s rewards program and earn cash back every time
                you dine at your favorite participating businesses.
              </p>
            </>
          )}

          {canShowButtons && (
            <MarketButtonGroup
              className={styles.subHeaderControls}
              alignment="left"
            >
              <MarketButton
                rank="primary"
                size="small"
                href="/signin"
                data-testid="index__signin-button"
                onClick={trackSignInButtonClick}
              >
                Sign in
              </MarketButton>
              <MarketButton
                className={styles.howItWorks}
                rank="tertiary"
                size="small"
                data-testid="index__how-it-works-button"
                onClick={openHowItWorksModal}
              >
                How it works
              </MarketButton>
            </MarketButtonGroup>
          )}
        </div>
        {!account && (
          <div className={styles.imageContainer}>
            <TextBubble
              text="You’ve earned cash back on your purchase!"
              iconSource="/icons/cashsign.svg"
            />
            <img
              className={styles.image}
              src="/images/square-local-offers-subheader.png"
              alt="Earning cash back with Square Local Offers"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SubHeader;
