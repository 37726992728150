import { MarketButton } from '@market/react';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { CheckIcon, LockOffIcon } from 'svgs';

import { useAccount } from 'hooks/use-account';
import { useAccountHasTransactionBonus } from 'hooks/use-banners';

import Offer from 'models/offer';

import {
  DEFAULT_CURRENCY_CODE,
  QUERY_PARAM_SELECTED_MERCHANT_SLUG,
  REGION_NOT_AVAILABLE_TYPE,
  ValidRegion,
} from 'lib/constants';
import { diffDays } from 'lib/date';
import { pageClickEvent } from 'lib/eventstream/event-catalog';
import ES2Tracker from 'lib/eventstream/tracker';
import { formatMoney } from 'lib/formatters';
import { getMerchantUrl } from 'lib/utilities';

import MerchantLogo from './merchant-logo';
import styles from './offer-card.module.css';
import { PeelOffButton } from './peel-off-button';
import Rating from './rating';

type OfferCardProps = {
  offer: Offer;
  setNewActivatedOffersList: (offer: Offer) => void;
  isActivated: boolean;
  region: ValidRegion | REGION_NOT_AVAILABLE_TYPE;
};

const OfferCard = ({
  offer,
  setNewActivatedOffersList,
  isActivated,
  region,
}: OfferCardProps) => {
  const { account, isLoading: isAccountLoading } = useAccount();
  const router = useRouter();
  const [isActivateButtonLoading, setIsActivateButtonLoading] = useState(false);
  const merchantUrl = getMerchantUrl(offer);
  const hasTransactionBonus = useAccountHasTransactionBonus();
  const defaultLocation = offer.locations[0];

  const onClickRevealOffer = (
    event: React.MouseEvent<HTMLMarketButtonElement, MouseEvent>,
    offer: Offer,
  ) => {
    event.stopPropagation();

    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Marketplace: Sign In: Reveal: ${region}`,
        {
          detail: JSON.stringify({
            location: 'Index',
            offer: offer.merchantToken,
            ...(account && { account: account.id }),
          }),
        },
      ),
    );

    router.push({
      pathname: '/signin',
      query: {
        [QUERY_PARAM_SELECTED_MERCHANT_SLUG]: defaultLocation?.slug,
      },
    });
  };

  const onActivateButtonClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    event.stopPropagation();
    setIsActivateButtonLoading(true);
    const es2Params = {
      detail: JSON.stringify({
        location: 'MarketPlace',
        offer: offer.merchantToken,
        ...(account && { account: account.id }),
      }),
    };
    ES2Tracker.trackPageClick(
      pageClickEvent(
        'button-click',
        `Marketing: Buyer: Cash Back: Activate: ${region}`,
        es2Params,
      ),
    );
    await setNewActivatedOffersList(offer);
    setIsActivateButtonLoading(false);
  };

  const onCardClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((event.target as Element)?.id === 'cards-button') {
      event.preventDefault();
      onActivateButtonClick(event);
    } else {
      router.push({
        pathname: merchantUrl,
      });
    }
  };

  const ButtonText = isActivated ? (
    <>
      <CheckIcon />
      Activated
    </>
  ) : (
    'Activate'
  );

  return (
    <div
      id="offer-card-container"
      className={styles.offerCardContainer}
      onClick={e => {
        onCardClick(e);
      }}
    >
      <div className={styles.headerContainer}>
        {hasTransactionBonus && (
          <div className={`semibold-20 ${styles.pill}`}>
            <LockOffIcon className={styles.pillIcon} />
            <span>2x cash back</span>
          </div>
        )}
        <img
          className={styles.headerImage}
          src={defaultLocation.headerUrl}
          alt={`Image of ${defaultLocation.name}`}
        />
      </div>
      <div className={styles.body}>
        <div className={styles.logo}>
          <MerchantLogo location={defaultLocation} size={80} />
        </div>
        <p className={`heading-5 grey-text ${styles.hint}`}>
          {offer?.locations?.some(l => l.websiteUrl)
            ? 'ONLINE & IN-STORE'
            : 'IN-STORE'}
        </p>
        <p className={`${styles.locationName} heading-20 black-text`}>
          {defaultLocation.name}
        </p>

        {!isAccountLoading && !account && (
          <>
            <Rating
              isNumPositionFront={false}
              showReviews={false}
              merchantToken={defaultLocation.merchantToken}
              location={defaultLocation}
              region={region}
              color="#000"
            />
            <PeelOffButton>
              <MarketButton
                data-testid="cards-sign-in-reveal-button"
                rank="primary"
                type="submit"
                onClick={e => {
                  onClickRevealOffer(e, offer);
                }}
              >
                Sign in to reveal offer
              </MarketButton>
            </PeelOffButton>
          </>
        )}
        {!isAccountLoading && account && (
          <>
            <p className="semibold-20">
              {hasTransactionBonus ? (
                <>
                  Get <s>{offer.percentage}%</s>&nbsp;
                  <span className={styles.discountGreen}>
                    {offer.percentage * 2}% cash back
                  </span>
                </>
              ) : (
                `Get ${offer.percentage}% cash back`
              )}
            </p>

            <div className={styles.requirements}>
              {offer.expiresAt && (
                <div className={styles.requirement}>
                  <Image
                    src="/icons/clock.svg"
                    alt="Expiry Icon"
                    width={12}
                    height={12}
                  />
                  <p className={`paragraph-10`} suppressHydrationWarning={true}>
                    {`${diffDays(offer.expiresAt)} days left`}
                  </p>
                </div>
              )}
              {offer.minPurchaseAmount && (
                <div className={styles.requirement}>
                  <p className={`paragraph-10 ${styles.seperator}`}>|</p>
                  <Image
                    src="/icons/tag.svg"
                    alt="Min Purchase Amount Icon"
                    width={12}
                    height={12}
                  />
                  <p className={`paragraph-10`}>{`Min purchase ${formatMoney(
                    {
                      amount: offer.minPurchaseAmount,
                      currencyCode: DEFAULT_CURRENCY_CODE,
                    },
                    0,
                  )}`}</p>
                </div>
              )}

              {offer.maxEarningAmount && (
                <>
                  {offer.minPurchaseAmount ? (
                    <div className={styles.break} />
                  ) : (
                    <p className={`paragraph-10 ${styles.seperator}`}> |</p>
                  )}
                  <div className={styles.requirement}>
                    <Image
                      src="/icons/upload.svg"
                      alt="Max cash back Icon"
                      width={12}
                      height={12}
                    />
                    <p className={`paragraph-10`}>{`Max cash back ${formatMoney(
                      {
                        amount: offer.maxEarningAmount,
                        currencyCode: DEFAULT_CURRENCY_CODE,
                      },
                      0,
                    )}`}</p>
                  </div>
                </>
              )}
            </div>
            <div className="flex">
              <MarketButton
                id="cards-button"
                data-testid={`activate-button-${offer.id}`}
                rank="primary"
                type="submit"
                {...(isActivateButtonLoading && { 'is-loading': true })}
                className={`${isActivated && styles.activate}`}
              >
                {ButtonText}
              </MarketButton>
              <MarketButton
                id="cards-detail-button"
                data-testid="details-button"
                className={styles.details}
                rank="tertiary"
                type="submit"
              >
                See Details
              </MarketButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OfferCard;
